<template>
  <section class="intro">
    <h1>We've all been stuck behind something while racing and it ALWAYS costs us a minute but how long were you really delayed for?</h1>
    <!-- <img class="image" src="../assets/intro.png" /> -->
  </section>



</template>
<script>
export default {
 name: "Intro"
}
</script>
<style lang="css" scoped>

.image {
  width: 50%;
  height: auto;
}

h1 {
  font-size: 1em;
}

</style>